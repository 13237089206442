import React, { useState, useEffect } from 'react';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space, Button} from 'antd';

import './language.css';

const language_key = 'language';

const LanguageSettings:React.FC = (props, ref) => {

    const [language, setLanguage] = useState('Chinese');

    useEffect(() => {
      // 仅在客户端执行，避免了服务器端渲染时的错误
      const storedLanguage = localStorage.getItem(language_key);
      if (storedLanguage) {
          setLanguage(storedLanguage);
      }
    }, []); // 空依赖数组意味着这个effect只会在组件挂载时运行一次

    const items: MenuProps['items'] = [
        {
          key: 'cn',
          label: (
            <a onClick={(e) => settings('cn')}>
                Chinese
            </a>
          ),
        },
        {
            key: 'en',
            label: (
              <a onClick={(e) => settings('en')}>
                English
              </a>
            ),
          },
    ]

    const settings = (key) => {
        let event:any = new Event("setLocaleEvent");
        event.key = language_key;
        
        if (key == 'en') {
            setLanguage('English');
            localStorage.setItem(language_key, 'English');
            event.value = 'English';
            window.dispatchEvent(event);
        } else if (key == 'cn') {
            setLanguage('Chinese');
            localStorage.setItem(language_key, 'Chinese');
            event.value = 'Chinese';
            window.dispatchEvent(event);
        }
        
    };

    return (
        <>
        <div className="language-header">
        <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
            <Space>
                {language}
                <DownOutlined />
            </Space>
            </a>
        </Dropdown>
        </div>
        </>
    );
}

export default LanguageSettings;