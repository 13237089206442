'use client';

import Image from "next/image";
import LanguageSettings from "@/components/language";
import "./home.css";
import { use, useEffect, useState } from "react";
import { TinyColor } from '@ctrl/tinycolor';
import { Button, ConfigProvider, Space } from 'antd';

const language_key = 'language';
const colors1 = ['#6253E1', '#04BEFE'];
const getHoverColors = (colors: string[]) =>
  colors.map((color) => new TinyColor(color).lighten(5).toString());
const getActiveColors = (colors: string[]) =>
  colors.map((color) => new TinyColor(color).darken(5).toString());

const Home = ({}) => {
  
  const [locale, setLocale] = useState('Chinese');

  useEffect(() => {
    setLocale((localStorage.getItem(language_key) || 'Chinese'));

    // 定义处理storage事件的函数
    const handleStorageChange = (event) => {
      console.log("storage change", event.key, event.value);
      if (event.key === language_key) {
        setLocale(event.value || 'Chinese');
      }
    };

    console.log("add storage listener");
    // 添加storage事件监听器
    window.addEventListener('setLocaleEvent', handleStorageChange);

    // 清理函数：移除事件监听器
    // return () => {
    //   window.removeEventListener('storage', handleStorageChange);
    // };
  }, []);

  const gotoQuick = () => {
    return () => {
      window.location.href = '/dashboard/new';
    };
  }

  return (
    <>
      <LanguageSettings />
      <div className="home">
        {locale != '' ? (
          <div className="home-quick">
            <p className="info">欢迎，当前语言为{locale}.</p>
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    colorPrimary: `linear-gradient(135deg, ${colors1.join(', ')})`,
                    colorPrimaryHover: `linear-gradient(135deg, ${getHoverColors(colors1).join(', ')})`,
                    colorPrimaryActive: `linear-gradient(135deg, ${getActiveColors(colors1).join(', ')})`,
                    lineWidth: 0,
                  },
                },
              }}
            >
              <Button type="primary" size="large" onClick={gotoQuick()} className="btn">
                快速开始
              </Button>
            </ConfigProvider>
          </div>
        ) : (
          <p>你需要登录！</p>
          
        )}
      </div>
    </>
  );
};

export default Home;
